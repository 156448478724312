import { AdminUrls, campusWebsiteUrls, getActivProfile, StudentUrls } from "../Profiles/Profiles";
import { ProfileConstant } from "./ProfileConstants";

const baseURL = getActivProfile(ProfileConstant.Prod);

export const campusAdminUrls =  AdminUrls.Prod
export const campusStudentUrls = StudentUrls.Prod
export const campusPlacementWebsite = campusWebsiteUrls.Prod

export const Url = { 
  getAllInstitute : baseURL + "institute/allInstitute",
  countryCodes: baseURL + "countrycodes",
  sendEnquiry : baseURL + "public/enquiry"
}