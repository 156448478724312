import Lottie from "lottie-react";
import React from "react";
import ReactModal from "react-modal";
import oopsAnimation from "../../Assets/animations/Opps.json";
import "./ErrorModal.css";

const ErrorModal = ({
  message,
  isOpen = false,
  onRequestClose,
  onSelect,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="errormodal"
      overlayClassName="errorModalOverlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="warningOrAlert">
        <div className="lottiewrapper">
          <Lottie
            loop={true}
            animationData={oopsAnimation}
            className="errorlottie"
          />
        </div>
        <div className="errormsg">{message}</div>

        <div className="buttons">
          <button onClick={onSelect}>
            OK
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default ErrorModal;
