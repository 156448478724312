import React, { useState, useEffect } from "react";
import "./ContactUs.css";

import "react-phone-number-input/style.css";
import { getAPICall, postAPICall } from "../../APIMethods/APIMethods";
import { Url } from "../../Constants/ApiUrlConstants";
import { ConstantComponent } from "../../ConstatantScreen/ConstantScreen";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    mobileNumber: "",
    collegeName: "",
    designation: "",
    message: "",
  });

  const [formDataError, setFormDataError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    collegeName: "",
    designation: "",
    message: "",
  });

  const [countryCodes, setCountryCodes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [loaderOpen, setLoaderOpen] = useState(false);

  // --------------------------------------------------------------------------------------------------

  const getCountryCodes = async () => {
    try {
      const countryCodes = await getAPICall(Url?.countryCodes);
      if (countryCodes?.SUCCESS) {
        setCountryCodes(countryCodes?.DATA);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCountryCodes();
  }, []);

  const onRequestClose = () => {
    setIsOpen(false);
    setErrorMessage("");
  };

  const onSelect = () => {
    setIsOpen(false);
    setErrorMessage("");
  };

  const onSuccessSelect = () => {
    setIsSuccessOpen(false);
    setSuccessMessage("");
  };

  const onSuccessClose = () => {
    setIsSuccessOpen(false);
    setSuccessMessage("");
  };

  const validateForm = (field, value) => {
    let errorMessage = "";
    const regex = /^\s+/;
    const onlyCharacter = /[0-9]/;
    const numRegex = /^[0-9]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    switch (field) {
      case "firstName":
        if (!value) {
          errorMessage = "First Name is required.";
        } else if (regex.test(value)) {
          errorMessage = "Field should not contain empty space.";
        } else if (onlyCharacter.test(value)) {
          errorMessage = "Field should not contain numbers.";
        }
        break;

      case "lastName":
        if (!value) {
          errorMessage = "Last Name is required.";
        } else if (regex.test(value)) {
          errorMessage = "Field should not contain empty space.";
        } else if (onlyCharacter.test(value)) {
          errorMessage = "Field should not contain numbers.";
        }
        break;

      case "email":
        if (!value) {
          errorMessage = "Email is required.";
        } else if (regex.test(value)) {
          errorMessage = "Field should not contain empty space.";
        } else if (!emailRegex.test(value)) {
          errorMessage = "The Email format is not valid.";
        }
        break;

      case "mobileNumber":
        if (!value) {
          errorMessage = "Mobile Number is required.";
        } else if (!numRegex.test(value)) {
          errorMessage = "Field should contain numbers only.";
        } else if (value.length !== 10) {
          errorMessage = "Mobile number should contains 10 digits only.";
        }

        break;

      case "collegeName":
        if (regex.test(value)) {
          errorMessage = "Field should not contain empty space.";
        } else if (onlyCharacter.test(value)) {
          errorMessage = "Field should not contain numbers.";
        }
        break;

      case "designation":
        if (onlyCharacter.test(value)) {
          errorMessage = "Field should not contain numbers.";
        } else if (onlyCharacter.test(value)) {
          errorMessage = "Field should not contain numbers.";
        }
        break;

      case "message":
        if (!value) {
          errorMessage = "Message is required.";
        } else if (onlyCharacter.test(value)) {
          errorMessage = "Field should not contain numbers.";
        }
        break;

      default:
        errorMessage = "";
    }

    setFormDataError((prevstate) => ({
      ...prevstate,
      [field]: errorMessage,
    }));

    return errorMessage;
  };

  const handleChange = (field, value) => {
    validateForm(field, value);
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleResetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      collegeName: "",
      designation: "",
      message: "",
    });

    setFormDataError({
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      collegeName: "",
      designation: "",
      message: "",
    });
  };

  const submitFormData = async () => {
    const dataToSend = {
      ...formData,
      countryCode: {
        countryCodeId: formData?.countryCode ? formData?.countryCode : 249,
      },
    };

    const firstName = validateForm("firstName", formData?.firstName);
    const lastName = validateForm("lastName", formData?.lastName);
    const emailId = validateForm("email", formData?.email);
    const messageText = validateForm("message", formData?.message);
    const mobileNumber = validateForm("mobileNumber", formData?.mobileNumber);

    const error = [];
    let isValid = true;

    error.push(firstName, lastName, emailId, messageText, mobileNumber);

    error.forEach((item) => {
      if (item) {
        isValid = false;
        setIsOpen(true);
        setErrorMessage("Please fill the required fields.");
      }
    });

    if (isValid) {
      try {
        setLoaderOpen(true);
        const sendFormData = await postAPICall(Url?.sendEnquiry, dataToSend);
        if (sendFormData?.SUCCESS) {
          setLoaderOpen(false);
          setIsSuccessOpen(true);
          setSuccessMessage("Message sent! We'll be in touch soon.");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: "",
            collegeName: "",
            designation: "",
            message: "",
          });
          setFormDataError({
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: "",
            collegeName: "",
            designation: "",
            message: "",
          });
        } else {
          setLoaderOpen(false);
          setIsOpen(true);
          setErrorMessage(
            sendFormData.message
              ? sendFormData.message
              : "Something went wrong."
          );
        }
      } catch (err) {
        setLoaderOpen(false);
        console.log(err, "Api call is failed.");
      }
    }
  };

  return (
    <div className="contactuswrapper">
      <div className="contactus">
        <div className="splitscreen">
          <div className="leftside"></div>
          <div className="rightside">
            <div className="formheading">
              <div className="formtitle">
                <span>Get In Touch</span>
              </div>
            </div>

            <div className="formfields">
              <div className="fieldwrapper">
                <div className="fieldrow">
                  <div className="name">
                    <div className="fieldlabel">
                      <label htmlFor="firstName">
                        Name<span className="star">*</span>
                      </label>
                    </div>
                    <div className="fieldinput">
                      <div className="firstname">
                        <input
                          type="text"
                          id="firstName"
                          placeholder="First Name"
                          value={formData?.firstName}
                          onChange={(e) =>
                            handleChange("firstName", e.target.value)
                          }
                        ></input>
                        <div className="error">
                          {formDataError?.firstName && (
                            <span className="errorfield">
                              {" "}
                              {formDataError?.firstName}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="lastname">
                        <input
                          type="text"
                          id="lastname"
                          placeholder="Last Name"
                          value={formData?.lastName}
                          onChange={(e) =>
                            handleChange("lastName", e.target.value)
                          }
                        ></input>
                        <div className="error">
                          {formDataError?.lastName && (
                            <span className="errorfield">
                              {" "}
                              {formDataError?.lastName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="email">
                    <div className="fieldlabel">
                      <label htmlFor="emailID">
                        Email<span className="star">*</span>
                      </label>
                    </div>

                    <div className="fieldinput">
                      <input
                        type="text"
                        id="emailID"
                        placeholder="Enter Your Email ID"
                        value={formData?.email}
                        onChange={(e) => handleChange("email", e.target.value)}
                      ></input>
                      <div className="error">
                        {formDataError?.email && (
                          <span className="errorfield">
                            {" "}
                            {formDataError?.email}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="phone">
                    <div className="fieldlabel">
                      <label htmlFor="call">
                        Phone<span className="star">*</span>
                      </label>
                    </div>

                    <div className="fieldinput">
                      <select
                        value={formData?.countryCode}
                        onChange={(e) =>
                          handleChange("countryCode", e.target.value)
                        }
                      >
                        {countryCodes.map((item) => {
                          return (
                            <option value={item?.countryCodeId}>
                              +{item?.countryCode}
                            </option>
                          );
                        })}
                      </select>
                      <input
                        type="text"
                        placeholder="Enter your phone number."
                        onChange={(e) =>
                          handleChange("mobileNumber", e.target.value)
                        }
                        value={formData?.mobileNumber}
                        maxLength={10}
                      />
                    </div>
                    <div className="error">
                      {formDataError?.mobileNumber && (
                        <span className="errorfield">
                          {" "}
                          {formDataError?.mobileNumber}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="college">
                    <div className="fieldlabel">
                      <label htmlFor="collegeName">
                        College/University Name
                      </label>
                    </div>

                    <div className="fieldinput">
                      <input
                        type="text"
                        id="collegeName"
                        placeholder="Enter Your College/University Name"
                        value={formData?.collegeName}
                        onChange={(e) =>
                          handleChange("collegeName", e.target.value)
                        }
                      ></input>
                      <div className="error">
                        {formDataError?.collegeName && (
                          <span className="errorfield">
                            {" "}
                            {formDataError?.collegeName}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="designation">
                    <div className="fieldlabel">
                      <label htmlFor="designationName">Designation</label>
                    </div>

                    <div className="fieldinput">
                      <input
                        type="text"
                        id="designationName"
                        placeholder="Enter Your Designation"
                        value={formData?.designation}
                        onChange={(e) =>
                          handleChange("designation", e.target.value)
                        }
                      ></input>
                    </div>
                    <div className="error">
                      {formDataError?.designation && (
                        <span className="errorfield">
                          {" "}
                          {formDataError?.designation}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="message">
                    <div className="fieldlabel">
                      <label htmlFor="messageText">
                        Message<span className="star">*</span>
                      </label>
                    </div>

                    <div className="fieldinput">
                      <textarea
                        id="messageText"
                        placeholder="Type your message here..."
                        value={formData?.message}
                        onChange={(e) =>
                          handleChange("message", e.target.value)
                        }
                      ></textarea>
                      <div className="error">
                        {formDataError?.message && (
                          <span className="errorfield">
                            {" "}
                            {formDataError?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="actionButtons">
                    <div className="btnwrapper">
                      <div className="resetbtn">
                        <button
                          type="button"
                          className="reset"
                          onClick={handleResetForm}
                        >
                          Reset
                        </button>
                      </div>
                      <div className="submitBtn">
                        <button
                          type="button"
                          className="submit"
                          onClick={submitFormData}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConstantComponent.ErrorModal
        message={errorMessage}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        onSelect={onSelect}
      />

      <ConstantComponent.SuccessModal
        message={successMessage}
        isOpen={isSuccessOpen}
        onRequestClose={onSuccessClose}
        onSelect={onSuccessSelect}
      />

      <ConstantComponent.LoaderModal
        loaderOpen={loaderOpen}
        setLoaderOpen={setLoaderOpen}
      />
    </div>
  );
};

export default ContactUs;
