
import React, { useEffect, useState } from 'react';
import './Institutes.css';
import { ApiCall } from '../../APIMethods/APIMethods';
import { Url } from '../../Constants/ApiUrlConstants';
import instituteIcon from '../../Assets/InstituteIcon.svg'

const Institutes = () => {
    const [instituteDetails, setInstituteDetails] = useState([])
    useEffect(() => {
      ApiCall(Url.getAllInstitute, "GET")
      .then((res)=>{
        console.log(res);
        setInstituteDetails(res?.DATA)
      })
    }, [])
    return (
        <div className="companies-page">
            <header className="header">
                <h1>Institutes</h1>
            </header>
            <div className="search-bar">
                {/* <input type="text" placeholder="Search Companies Here..." /> */}
            </div>
            <div className="companies-list">
                {instituteDetails.map(item => (
                    <div key={item.instituteUid} className="institute-card">
                        <img
                            src={item.instituteLogo || instituteIcon}
                            alt={item.instituteName}
                        />
                        <p title={item.instituteName}>{item.instituteName}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Institutes;
