import Lottie from "lottie-react";
import React from "react";
import ReactModal from "react-modal";
import "./SuccessModal.css";
import successAnimation from "../../Assets/animations/Success.json";

const SuccessModal = ({ message, isOpen, onRequestClose, onSelect }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="successmodal"
      overlayClassName="successModalOverlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="warningOrAlert">
        <div className="lottiewrapper">
          <Lottie
            loop={true}
            animationData={successAnimation}
            className="successlottie"
          />
        </div>
        <div className="successmsg">{message}</div>

        <div className="buttons">
          <button onClick={onSelect}>OK</button>
        </div>
      </div>
    </ReactModal>
  );
};

export default SuccessModal;
