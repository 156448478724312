import React, { useState } from "react";
import quikHireLogo from "../../Assets/quikHireLogo.png";
import "./Header.css";
import Modal from "react-modal";
import instituteIcon from "../../Assets/InstituteIcon.svg";
import studentIcon from "../../Assets/StudentIcon.svg";
import { useNavigate } from "react-router-dom";
import {
  campusAdminUrls,
  campusStudentUrls,
} from "../../Constants/ApiUrlConstants";
import { FaBars } from "react-icons/fa";

const Header = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const handleOpenMenu = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  const handleLogin = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleInstituteLogin = () => {
    window.open(campusAdminUrls, "_blank");
  };
  const handleStudentLogin = () => {
    window.open(campusStudentUrls, "_blank");
  };
  const navigate = useNavigate();
  const navigateToModule = (path) => {
    navigate(`/${path}`);
  };
  return (
    <div className="headerMainDiv">
      <div className="logoImage">
        <img src={quikHireLogo} alt="" />
        <small className="poweredBy">Powered by QuikHire</small>
      </div>
      <div className="menuMainDiv">
        <ul className="nav-Items">
          <li
            className="nav-Item"
            onClick={() => {
              navigateToModule("home");
            }}
          >
            Home
          </li>
          {/* <li className='nav-Item' onClick={()=>{navigateToModule('institutes')}}>Institute</li> */}
          {/* <li
            className="nav-Item"
            onClick={() => {
              navigateToModule("aboutUs");
            }}
          >
            About Us
          </li> */}
          <li
            className="nav-Item"
            onClick={() => {
              navigateToModule("contactus");
            }}
          >
            Contact Us
          </li>
        </ul>

        <div className="hamburgericon">
          <button className="hammenuIcon" onClick={handleOpenMenu}>
            <FaBars />
          </button>
        </div>
        {openMobileMenu && (
          <div className="mobileNav">
            <div className="mob-navwrapper">
              <div
                className="mobilenav_item"
                onClick={() => {
                  navigateToModule("home");
                }}
              >
                Home
              </div>
              {/* <div className='mobilenav_item' onClick={()=>{navigateToModule('institutes')}}>Institute</div> */}
              {/* <div
                className="mobilenav_item"
                onClick={() => {
                  navigateToModule("aboutUs");
                }}
              >
                About Us
              </div> */}
              <div
                className="mobilenav_item"
                onClick={() => {
                  navigateToModule("contactus");
                }}
              >
                Contact Us
              </div>
              <div>
                <button
                  className="longinBtn mobilelogin"
                  onClick={() => {
                    handleLogin();
                  }}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="userdiv bigscreen">
        <button
          className="longinBtn"
          onClick={() => {
            handleLogin();
          }}
        >
          Login
        </button>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={modalOpen}
        onRequestClose={handleCloseModal}
        overlayClassName={"modalOverlayStyle"}
        className={`modalStyle ModalCss`}
      >
        <div className="modalHeader">
          <h2>Select One</h2>
          <button onClick={handleCloseModal} className="Close_Button">
            ×
          </button>
        </div>
        <div className="modalBody">
          <div
            className="instituteDiv"
            onClick={() => {
              handleInstituteLogin();
            }}
          >
            <img src={instituteIcon} alt="" />
            <h4>Education Institute</h4>
          </div>
          <div
            className="instituteDiv studentDiv"
            onClick={() => {
              handleStudentLogin();
            }}
          >
            <img src={studentIcon} alt="" />
            <h4>Student</h4>
          </div>
        </div>
        {/* <div className="modalFooter">
    <button onClick={handleCloseModal}>Close</button>
    <button onClick={()=>{}}>Save Changes</button>
  </div> */}
      </Modal>
    </div>
  );
};

export default Header;
