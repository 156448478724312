import React from 'react'
import { ConstantComponent } from '../../ConstatantScreen/ConstantScreen'
import './Dashboard.css'
import { Navigate, Route, Routes } from 'react-router-dom'
const Dashboard = () => {
  return (
    <div className='mainDashboardDiv'>
      <div className="headerDiv">
        <ConstantComponent.Header />
      </div>
      <div className="heroDiv">
        <Routes>
          <Route path='/' element={<Navigate to={'/home'} />} />
          <Route path='/home' element={<ConstantComponent.Home />} />
          {/* <Route path='/institutes' element={<ConstantComponent.Institutes />} /> */}
          {/* <Route path='/aboutUs' element={<ConstantComponent.AboutUs />} /> */}
          <Route path='/termsAndCondition' element={<ConstantComponent.TermsAndConditions />} />
          <Route path='/privacyPolicy' element={<ConstantComponent.PrivacyPolicy />} />
          <Route path= '/contactus' element = {<ConstantComponent.ContactUs />} />
        </Routes>
      </div>
      <div className="footerDiv">
      <ConstantComponent.Footer />
      </div>
      {/* <div >
        <ConstantComponent.WhatsAppChat />
      </div> */}
    </div>
  )
}

export default Dashboard