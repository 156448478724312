import React, { useEffect, useState } from 'react'
import './Home.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import officerImg1 from '../../Assets/Officers/01.png'
import officerImg2 from '../../Assets/Officers/02.png'
import officerImg3 from '../../Assets/Officers/03.png'
import officerImg4 from '../../Assets/Officers/04.png'
import officerImg5 from '../../Assets/Officers/05.png'
import officerImg6 from '../../Assets/Officers/06.png'
import officerImg7 from '../../Assets/Officers/07.png'
import officerImg8 from '../../Assets/Officers/08.png'
import officerImg9 from '../../Assets/Officers/09.png'
import studentImg1 from '../../Assets/students/1.png'
import studentImg2 from '../../Assets/students/2.png'
import studentImg3 from '../../Assets/students/3.png'
import studentImg4 from '../../Assets/students/4.png'
import studentImg5 from '../../Assets/students/5.png'
import CompanyLogo2 from '../../Assets/companyLogo2.png'
import CompanyLogo1 from '../../Assets/companyLogo1.png'
import CompanyLogo3 from '../../Assets/companyLogo3.png'
import CompanyLogo4 from '../../Assets/companyLogo4.png'
import CompanyLogo5 from '../../Assets/companyLogo5.png'
import { ApiCall } from '../../APIMethods/APIMethods';
import { Url } from '../../Constants/ApiUrlConstants';
const Home = () => {
  const [instituteDetails, setInstituteDetails] = useState([])
  const filteredItems = instituteDetails?.filter(item => item.instituteLogo) || [];
  const itemCount = filteredItems.length;
  // const settings = {
  //   dots: false,
  //   arrows: itemCount > 1, // Show arrows only if there's more than 1 item
  //   infinite: itemCount > 4, // Enable infinite only if there are more than 4 items
  //   speed: 1000,
  //   slidesToShow: Math.min(5, itemCount),
  //   slidesToScroll: 3,
  //   autoplay: itemCount > 4, // Enable autoplay only if there are more than 4 items
  //   autoplaySpeed: 2000,
  // };
  const settings = {
    dots: false,
    arrows : false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  useEffect(() => {
    ApiCall(Url.getAllInstitute, "GET")
      .then((res) => {
        console.log(res);
        setInstituteDetails(res?.DATA)
      })
  }, [])

  return (
    <div className='mainHomeSection'>
      <div className='homeHeroDiv'>
        <div className='homeTextDiv'>
          <span>End to End Campus Placement</span>
          <h2>Automation Platform</h2>
        </div>
      </div>
      <div className='AccessDivRoleWise'>
        <div className="placementOfficerDiv">
          <div className="RoleHeading">
            <span>For Institutions</span>
          </div>
          <div className='accessList'>
            <div className='officerAccessList'>
              <div className='accessListItems'>
                <div className='accessListItem'>
                  <img src={officerImg1} alt="" />
                  <span>
                    Digitized Placement Process
                  </span>
                </div>
                <div className='accessListItem'>
                  <img src={officerImg6} alt="" />
                  <span>
                    Dedicated Institute Page
                  </span>
                </div>
                <div className='accessListItem'>
                  <img src={officerImg2} alt="" />
                  <span>
                    Student Repository & Engagement
                  </span>
                </div>
                <div className='accessListItem'>
                  <img src={officerImg7} alt="" />
                  <span>
                    5-Minute Onboarding/Exit
                  </span>
                </div>
                <div className='accessListItem'>
                  <img src={officerImg3} alt="" />
                  <span>
                    Controlled Access to Campus Jobs
                  </span>
                </div>
                <div className='accessListItem'>
                  <img src={officerImg8} alt="" />
                  <span>
                    Integration with Existing HR Systems
                  </span>
                </div>
                <div className='accessListItem'>
                  <img src={officerImg4} alt="" />
                  <span>
                  Access to Internships/Fresher Jobs
                  </span>
                </div>
                <div className='accessListItem'>
                  <img src={officerImg9} alt="" />
                  <span>
                  Dedicated Mobile Application (On-Demand) 
                  </span></div>
                <div className='accessListItem'>
                  <img src={officerImg5} alt="" />
                  <span>
                  Job Post Performance Analytics
                  </span></div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="studentAccessDiv">
          <div className="RoleHeading">
            <span>For Students</span>
          </div>
          <div className='accessList'>
            <div className='accessListItems'>
              <div className='accessListItem'>
                <img src={studentImg1} alt="" />
                <span>
                Targeted job notifications
                </span>
              </div>
              <div className='accessListItem'>
                <img src={studentImg4} alt="" />
                <span>
                Digitized Resume with a QR Code
                </span></div>
              <div className='accessListItem'>
                <img src={studentImg2} alt="" />
                <span>
                One click job submission
                </span>
              </div>
              <div className='accessListItem'>
                <img src={studentImg5} alt="" />
                <span>
                Access to Internships/fresher jobs
                </span></div>
              <div className='accessListItem'>
                <img src={studentImg3} alt="" />
                <span>
                Structured recruitment process flow
                </span></div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lastTenCampus">
        <div className='lastTenCampusHeading'>
          <span className='headingName'>Trusted By</span>
          <span className='line'></span>
        </div>
        <Slider {...settings}> 
        {instituteDetails?.filter(item => item.instituteLogo)?.map(item => (
            <div className="sliderSectionOne" key={item?.instituteUid}>
              <img src={item?.instituteLogo} alt={item?.instituteName} />
            </div>
          ))}
            <div className="sliderSectionOne">
              <img src={CompanyLogo1} />
            </div>
            <div className="sliderSectionOne">
              <img src={CompanyLogo2} />
            </div>
            <div className="sliderSectionOne">
              <img src={CompanyLogo3} />
            </div>
            <div className="sliderSectionOne">
              <img src={CompanyLogo4} />
            </div> 
            <div className="sliderSectionOne">
              <img src={CompanyLogo5} />
            </div> 
        </Slider>
        <small className='logoNote'>Note <sup style={{color : "red"}}>*</sup> : The logos shown are for illustrative purposes only and are used under public discretion. No business liability or relationship is implied.</small>
        {/* <Slider {...settings}>
          {instituteDetails?.filter(item => item.instituteLogo)?.map(item => (
            <div className="sliderSectionOne" key={item?.instituteUid}>
              <img src={item?.instituteLogo} alt={item?.instituteName} />
            </div>
          ))}
        </Slider> */}
      </div>
    </div>
  )
}

export default Home