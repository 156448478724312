import React from 'react'
import './TermsAndConditions.css'
import { useNavigate } from 'react-router-dom'
import { campusPlacementWebsite } from '../../Constants/ApiUrlConstants';
const TermsAndConditions = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div class="termsOfUseBox">
                <h4  >Terms and Conditions:</h4>
                <p class="mb-2">Welcome to <a href={campusPlacementWebsite} >Campus Placement</a>, your go-to platform for connecting with potential employers and job seekers! By opting in to our program, you can expect to receive authentication messages as required by the application.</p>
                <p class="mb-2">You can cancel the SMS service at any time. Just text "STOP" to the short code. After you send the SMS message "STOP" to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us. If you want to join again, just sign up as you did the first time and we will start sending SMS messages to you again.</p>

                <p class="mb-2">If you are experiencing issues with the messaging program you can reply with the keyword HELP for more assistance, or you can get help directly at <a  href="mailto:contact@campusplacement.in">contact@campusplacement.in</a>.</p>

                <p class="mb-2">Carriers are not liable for delayed or undelivered messages.</p>

                <p class="mb-2">As always, message and data rates may apply for any messages sent to you from us and to us from you. You will receive message when you are performing specific actions in the application. If you have any questions about your text plan or data plan, it is best to contact your wireless provider.</p>

                <p class="mb-2">If you have any questions regarding privacy, please read our privacy policy: <span className='activePrivacy' onClick={()=>{navigate('/privacyPolicy')}}>Privacy Policy</span>.</p>
                <p class="mb-4">Acceptance: By accessing and/or using any of the Services, you agree to be bound by this Agreement, whether you are a "Visitor" or a "Member". Your download or use of the Services constitutes acceptance of these terms, and you agree to abide by all applicable laws, rules, and regulations. You must indicate your acceptance of this Agreement during the registration process. We may modify or revise these Terms at any time, and you agree to be bound by such modifications or revisions.</p>

                <p class="mb-4">Third-Party Sites: Campus Placement may contain links to third-party websites. We have no control over and assume no responsibility for any third-party website's content, privacy policies, or practices. By using the Service, you relieve Campus Placement from any liability arising from your use of any third-party website. We encourage you to be aware when you leave the service and read the terms and conditions of each website you visit.</p>

                <p class="mb-4">Your Campus Placement Account: To use all features of the App, you may need to create an Account. You must provide accurate and complete information. You are solely responsible for your account activity and must keep your account credentials secured. You must notify us immediately of any security breach or unauthorized use of your account. All information collected through Accounts or otherwise through the Services is collected and used in accordance with the Privacy Policy.</p>

                <p class="mb-4">Contents: Campus Placement allows users to submit Content to the Services. By posting Content, you grant us a license to use, modify, and distribute such Content. You retain ownership rights in your Content, subject to the license granted herein.</p>

                <p class="mb-4">Restricted Contents: Users shall not post any information that violates this Agreement, including but not limited to content that is unlawful, discriminatory, fraudulent, or threatening.</p>

                <p class="mb-4">Content Posting Policy: Users shall not post content that violates the Agreement, including unlawful, discriminatory, fraudulent, or harassing content.</p>

                <p class="mb-4">Content Owner: User content is considered non-confidential and non-proprietary. Users are solely responsible for their content, and Campus Placement does not endorse any user-submitted content.</p>

                <p class="mb-4">Report/Block: Users can report any content in the application and provide proof. Campus Placement will review and remove violating content immediately.</p>

                <p class="mb-4">Intellectual Property Policy: Users must not infringe upon third-party intellectual property rights. Campus Placement reserves the right to remove infringing content and terminate user access for repeat infringement.</p>

                <p class="mb-4">Use of Your Information: Campus Placement may collect personal information to personalize user experience and may contact users for feedback and events participation.</p>

                <p class="mb-4">Referral Bonus Policy: Campus Placement offers a referral program with specified rules and rewards for qualified referrals.</p>

                <p class="mb-4">Product &amp; Pricing: Campus Placement offers a freemium model for recruiters and charges a nominal fee for premium features. Subscription plans are available with varying benefits and pricing.</p>

                <p class="mb-4">Payment Terms and Conditions: Campus Placement offers in-app purchases with specified payment terms. Users may cancel subscriptions subject to cancellation policy.</p>

                <p class="mb-4">User Termination Policy/Legal Action: Campus Placement may terminate user access and remove content for violations of this agreement.</p>

                <p class="mb-4">Contact Us: Email us at <a href="mailto:contact@campusplacement.in">contact@campusplacement.in</a> for any queries.</p>
            </div>
        </div>
    )
}

export default TermsAndConditions