import Header from "../Components/Header/Header";
import Dashboard from "../Screens/Dashboard/Dashboard";
import Home from "../Screens/Home/Home";
import Footer from "../Components/Footer/Footer";
import Institutes from "../Screens/Institutes/Institutes";
import AboutUs from "../Screens/AboutUs/AboutUs";
import TermsAndConditions from "../Screens/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../Screens/PrivacyPolicy/PrivacyPolicy";
import WhatsAppChat from "../Screens/WhatsAppChat/WhatsAppChat";
import ContactUs from './../Screens/ContactUs/ContactUs';
import ErrorModal from "../Screens/ErrorModal/ErrorModal";
import SuccessModal from "../Screens/SuccessModal/SuccessModal";
import LoaderModal from './../Screens/LoaderModal/LoaderModal';



export const ConstantComponent = {
Home,
Dashboard,
Header,
Footer,
Institutes,
AboutUs,
TermsAndConditions,
PrivacyPolicy,
WhatsAppChat,
ContactUs,
ErrorModal,
SuccessModal,
LoaderModal,
}