import Lottie from 'lottie-react'
import React from 'react'
import loaderJson from '../../Assets/animations/PANLoader.json'
import './LoaderModal.css'
import Modal from 'react-modal';

const LoaderModal = ({ loaderOpen }) => {
  return (
    <Modal 
    isOpen={loaderOpen}
    className="loadermodal"
    overlayClassName="loaderModalOverlay"
    shouldCloseOnOverlayClick={false}
    >
        <div className='loaderwrapper'>
        <div className='loader'>
            <Lottie animationData={loaderJson}  className='loaderfile' />
        </div>
    </div>
    </Modal>
    
  )
}

export default LoaderModal