import React from 'react'
import './PrivacyPolicy.css'
import { campusPlacementWebsite } from '../../Constants/ApiUrlConstants'
const PrivacyPolicy = () => {
    return (
        <div class="jobsummery pricacyPolicyBox">
            <h4 >Privacy Policy:</h4>
            <p>This Privacy Policy describes the practices by the “<a href={campusPlacementWebsite} >Campus Placement</a>” application. It explains the storage, disclosure, use, other processing of your data (Personal &amp; Non-Personal, and usage and sharing of contents). Hence, before start using this application or giving information about you, kindly review this Privacy Policy.</p>

            <h3>Information we collect through In-App Disclosure (aka Android Permission):</h3>
            <ul>
                <li>A. Storage: If allowed, we enable you to upload files from your device such as your Resume, Profile Picture, any document for verification of your profile, etc.</li><li>
                </li><li>B. Location: If allowed, we help to find Jobs/Candidates near your location.</li><li>
                </li><li>C. Contacts: If allowed, we enable you to invite your friends through SMS, and WhatsApp.</li><li>
                </li><li>D. Camera: If allowed, we enable you to shoot a picture or video.</li><li>
                </li><li>E. Microphone: If allowed, we enable you to record audio.</li><li>
                </li><li>F. Telephone: If allowed, we read the Phone Status and identity.</li><li>
                </li></ul>
            <h3>Other Information we collect during your usage of Campus Placement App:</h3>
            <ul>
                <li>A. When you connect to our application, we take some non-personal data about yourself like the Android model, version, browser, resolution of your mobile phone, IMEI, Language, network/Wi-Fi connectivity, Mobile carriers to provide you the best services.</li><li>
                </li><li>B. When you create your profile, we ask for your personal data like phone number, email ID, date of birth, etc in order to make your profile uniquely identifiable.</li><li>
                </li></ul>
            <h3>Third Party Tools, SDKs, Services used</h3>
            <ul>
                <li>A. Firebase SDK: Help us to develop and distribute App. Track Performance and Analytics.</li><li>
                </li><li>B. Send Bird: It helps you to communicate with each other efficiently within the App.</li><li>
                </li><li>C. Agora Video SDK: To provide you a video interview platform.</li><li>
                </li><li>D. Payment Gateway: It enables you make payments through various payment modes like UPI, Credit Card, Debit Card, Internet Banking, etc.</li><li>
                </li></ul>
            <h3>Policy for DND / NDNC</h3>

            <p>By using the Website and/or registering yourself at Campus Placement.in you authorize us to contact you via E-mail or phone call or SMS and offer you our services, imparting product knowledge, offer promotional offers running on website &amp; offers offered by the associated third parties, for which reasons, personally identifiable information may be collected. And irrespective of the fact if you have also registered yourself under DND or DNC or NCPR service, you still authorize us to give you a call from Campus Placement for the above mentioned purposes till 365 days of your registration with us. This Privacy Policy covers Campus Placement's treatment of personally identifiable information that Campus Placement collects when you are on the Campus Placement site, and when you use Campus Placement's services.</p>

            <p>This policy also covers Campus Placement's treatment of personally identifiable information that Campus Placement's business partner shares with Campus Placement. Campus Placement may also receive personally identifiable information from our business partners. When you register with Campus Placement, we ask for your first name, last name, contact no, email, company name &amp; job title. Once you register with Campus Placement and sign in to our services, you are not anonymous to us. Also, during registration, you may be requested to register your mobile phone and email id, pager, or other device to receive text messages, and other services to your wireless device. By registration you authorize us to send SMS/E-mail alerts to you for your login details and any other service requirements or some advertising messages/emails from us.</p>

            <h3>The Use of the Information Collected</h3>
            <ul>
                <li>A. To provide the best-personalized services to you.</li>

                <li>B. To facilitate you to provide all the information required in the recruitment/job search.</li>

                <li>C. Helps in research and statistical analysis of users to make business decisions.</li>

                <li>D. To improve and develop our Platform and conduct product development.</li>

                <li>E. To help us detect abuse, fraud, and illegal activity on the Platform and communicate to you.</li>
            </ul>
            <h3>How may your information be disclosed?</h3>

            <p>Personal Information: In general, we use the data internally to serve our Users and enable them to take maximum advantage of the Application. We do not disclose your Personal Information to any third party.</p>

            <p>B. Non-Personal Information: We may disclose Non-Personal Information to our trusted partners who shall comply with this privacy policy and the relevant privacy laws. We do not combine Non-Personal Information with Personal Information (such as combining your name with your unique User Device number). We strictly follow GDPR.</p>

            <h3>International Users:</h3>

            <p>Your personal and non-personal information may be stored and processed in any country where we provide our services as described in the Privacy Policy. By agreeing to our Privacy Policy and using our application you consent to transfer your personal and non-personal information to us despite the fact that different countries have different data protection rules. If you object to your Personal/non-personal Information being processed as described in this Policy, please do not use this Application.</p>

            <h3>Notification of Changes:</h3>

            <p>We reserve the right to change, alter this Privacy Policy at any time. It is your responsibility to keep checking it and stay updated on a regular basis. Your continued use of this application following the posting of any changes/modifications to the Privacy Policy constitutes your acceptance of the revised Privacy Policy.</p>

            <h3>M. Jurisdiction:</h3>

            <p>If you choose to visit the Apps/Services, your visit and any dispute over privacy are subject to this Privacy Policy and the Apps/Services' terms of use. Any disputes arising under this Privacy Policy shall be strictly governed by the laws of India.</p>

            <h3>Contact Us:</h3>
            <p>If you have any concerns regarding this privacy policy, please email us at <a href="mailto:contact@campusplacement.in">contact@campusplacement.in</a>.</p>

        </div>
    )
}

export default PrivacyPolicy