
const CSRF_Token = 'YmUyYjAzODItOGIyZi00Njk1LThhOGMtYjhkYzVmZDY0YjI2';

export const getAPICall = (api) => {
  const Token = sessionStorage.getItem('token');
  if (Token != "" || Token != null) {
    return fetch(api, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': CSRF_Token,
        "Authorization": "Bearer " + Token,
      },
    })
      .then((respone) => respone.json())
  } else {
    return fetch(api, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': CSRF_Token,
        // "Authorization": "Bearer " +Token,
      },
    })
      .then((respone) => respone.json())
  }

}



//Method for With Json Converted body
export const postAPICall = (api, body) => {
  const Token = sessionStorage.getItem('token');
  if (Token != "" || Token != null) {
    return fetch(api, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Authorization": "Bearer " + Token,
        'X-CSRF-Token': CSRF_Token
      },
      body: JSON.stringify(body),
    })
      .then((respone) => respone.json())
  } else {
    return fetch(api, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': CSRF_Token
      },
      body: JSON.stringify(body),
    })
      .then((respone) => respone.json())
  }

}

//Method For Registration User Active Deactive
export const postStringAPICall = (api, body) => {
  const Token = sessionStorage.getItem('token');
  if (Token != "" || Token != null) {
    return fetch(api, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Authorization": "Bearer " + Token,
        'X-CSRF-Token': CSRF_Token
      },
      body: body,
    })
      .then((respone) => respone.json())
  } else {
    return fetch(api, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': CSRF_Token
      },
      body: body,
    })
      .then((respone) => respone.json())
  }

}
export const putAPICall = (api, body) => {
  const Token = sessionStorage.getItem('token');
  return fetch(api, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Authorization": "Bearer " + Token,
      'X-CSRF-Token': CSRF_Token
    },
    body: JSON.stringify(body),
  })
    .then((respone) => respone.json())
}

export const patchAPICall = (api, body) => {
  const Token = sessionStorage.getItem('token');
  return fetch(api, {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Authorization": "Bearer " + Token,
      'X-CSRF-Token': CSRF_Token
    },
    body: JSON.stringify(body),
  })
    .then((respone) => respone.json())
}

export const deleteAPICall = (api) => {
  const Token = sessionStorage.getItem('token');
  return fetch(api, {
    method: "DELETE",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Authorization": "Bearer " + Token,
      'X-CSRF-Token': CSRF_Token
    },
  })
    .then((respone) => respone.json())
}


const GetAuth = (bool, isfile) => {
  let token = sessionStorage.getItem("token");
  return isfile ? {
    "Accept": "application/json",
    "Authorization": "Bearer " + token,
    "X-CSRF-Token": CSRF_Token
  } : bool ? {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer " + token,
    "X-CSRF-Token": CSRF_Token
  } : {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": CSRF_Token
  }
}

export const ApiCall = (
  APIUrl,
  Method,
  isAuthorizationRequired = false,
  ApiName = "",
  Body = null,
  isfile = false
) => {

  let APIPromise1 = new Promise((resolve, reject) => {
    const URLOptions = Method?.toUpperCase() == "GET" ? {
      method: "GET",
      headers: GetAuth(isAuthorizationRequired, isfile),
      redirect: 'follow'
    } : {
      method: Method,
      headers: GetAuth(isAuthorizationRequired, isfile),
      redirect: 'follow',
      body: isfile ? Body : JSON.stringify(Body),
    }
    fetch(APIUrl, URLOptions)
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(`${Method} ${ApiName} error`, err);
        reject(err);
      });
  });
  return APIPromise1;
};
