import React, { useEffect, useRef, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './WhatsAppChat.css'; // Import your styles
import { IoMdClose } from "react-icons/io";

const WhatsAppChat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const whatsAppRef = useRef();

  const toggleChat = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up to the body
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (whatsAppRef.current && !whatsAppRef.current.contains(event.target)) {
        setIsOpen(false); // Close the chat when clicking outside
      }
    };
    
    document.body.addEventListener('click', handleClick);
    
    // Cleanup the event listener
    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className="whatsapp-container">
      <div ref={whatsAppRef} className={`chat-window ${isOpen ? 'open' : ''}`}>
        <h3>HI THERE!</h3>
        <p>We are here to help you! Chat with us on WhatsApp for any queries.</p>
        <a
          href="https://api.whatsapp.com/send?phone=8806217353"
          target="_blank"
          rel="noopener noreferrer"
          className="chat-button"
        >
          <FaWhatsapp className="whatsapp-icon" /> Start Chat
        </a>
      </div>
      <button onClick={toggleChat} className="whatsapp-toggle">
        {isOpen ? 
          <IoMdClose className="whatsapp-icon" /> : 
          <FaWhatsapp className="whatsapp-icon" />
        }
      </button>
    </div>
  );
};

export default WhatsAppChat;
